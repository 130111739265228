<template>
  <div v-if="mainAcc">
    <sdModal
      centered
      type="primary"
      :title="modalTitle"
      class="action"
      :visible="visibleModal"
      :onCancel="closeModal"
      :width="windowSize > 850 ? 350 : 300"
      v-if="visibleModal"
    >
      <a-alert :message="error" type="warning" v-if="!isDataSend && error" show-icon />
      <div class="action__wrapper">
        <DepositModal
          :error="error"
          v-if="actionType === 'deposit'"
          @dataNotSended="dataNotSended"
          @dataSended="dataSended"
        />
        <WithdrawalModal
          :error="error"
          v-if="actionType === 'withdrawal'"
          @dataNotSended="dataNotSended"
          @dataSended="dataSended"
        />
        <TransferModal
          :error="error"
          v-if="actionType === 'transfer'"
          @dataNotSended="dataNotSended"
          @dataSended="dataSended"
        />
        <TradingModal
          :error="error"
          v-if="actionType === 'trading-deposit' || actionType === 'trading-withdrawal'"
          @dataNotSended="dataNotSended"
          @dataSended="dataSended"
          :tradingAccount="tradingAccount"
          :actionType="actionType"
        />
        <TradingClose
          :error="error"
          v-if="actionType === 'trading-close'"
          @dataNotSended="dataNotSended"
          @dataSended="dataSended"
          :tradingAccount="tradingAccount"
          :actionType="actionType"
        />
        <BuyModal :error="error" v-if="actionType === 'buy'" @dataNotSended="dataNotSended" @dataSended="dataSended" />
      </div>
    </sdModal>
    <sdModal
      centered
      type="primary"
      :title="
        i18n.t('start.modalHeader', {
          actionType: i18n.t('start.' + actionType, {
            msg: actionType === 'invest' ? i18n.t('start.on') : i18n.t('start.from'),
          }),
          msg: actionType !== 'deposit' && actionType !== 'invest' ? i18n.t('start.a') : '',
        })
      "
      :visible="visibleSendModal"
      :onCancel="closeSendModal"
      v-if="visibleSendModal"
      :width="windowSize > 700 ? (actionType == 'deposit' ? 700 : 400) : ''"
    >
      <!-- <div v-if="actionType !== 'deposit'">
        <div class="transaction__dialog" v-if="isDataSend">
          <p class="transaction__title-success transaction__title">
            {{ i18n.t(`start.transactionSendTitle`) }}
          </p>
          <p class="transaction__info" v-if="actionType != 'withdrawal'">
            {{
              i18n.t(
                `start.transactionSendInfo`,
                { days: actionType == 'invest' ? 5 : 21 },
                actionType == 'invest' ? 0 : accId == tokenAccount.id ? 1 : 2,
              )
            }}
          </p>
          <p v-else>
            {{ i18n.t(`start.withdrawalSend`, { day: i18n.d(withdrawalDate, 'short') }) }}
          </p>
        </div>
        <div class="transaction__dialog" v-else>
          <p class="transaction__title transaction__title-error">
            {{ i18n.t(`start.transactionNotSendTitle`) }}
          </p>
          <p class="transaction__info">{{ error[0] }}</p>
        </div>
        <router-link to="/accounts" style="width:100%; margin:top:20px;">
          <sdButton type="primary" style="width:100%;" @click="closeSendModal">{{
            i18n.t('start.toAccounts')
          }}</sdButton>
        </router-link>
      </div> -->
      <div class="payment-info" v-if="!error">
        <DepositCoinpayments :dataSource="dataSource" />
      </div>
      <!--<div v-else>
        <div class="transaction__dialog" v-if="isDataSend">
          <p class="transaction__title transaction__title-success">
            {{ i18n.t(`start.depositSendTitle`) }}
          </p>
          <p class="transaction__info">
            {{ i18n.t(`start.depositSendInfo`) }}
          </p>
        </div>
        <div class="transaction__dialog" v-else>
          <p class="transaction__title transaction__title-error">
            {{ i18n.t(`start.depositNotSendTitle`) }}
          </p>
          <p class="transaction__info">
            {{ error[0] }}
          </p>
        </div>
        <router-link to="/accounts" style="width:100%; margin:top:20px;">
          <sdButton type="primary" style="width:100%;">{{ i18n.t('start.toAccounts') }}</sdButton>
        </router-link>
      </div>-->
    </sdModal>
  </div>
</template>
<script>
import { computed, defineAsyncComponent, onMounted, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { depositCurrencies } from '@/config/helpers/JSONdata';
const DepositCoinpayments = defineAsyncComponent(() => import('./overview/DepositCoinpayments'));

const DepositModal = defineAsyncComponent(() => import('./overview/DepositModal'));
const TransferModal = defineAsyncComponent(() => import('./overview/TransferModal'));
const WithdrawalModal = defineAsyncComponent(() => import('./overview/WithdrawalModal'));
const BuyModal = defineAsyncComponent(() => import('./overview/BuyModal'));
const TradingModal = defineAsyncComponent(() => import('./overview/TradingModal'));
const TradingClose = defineAsyncComponent(() => import('./overview/TradingClose'));

const ModalCash = {
  name: 'ModalCash',
  components: {
    DepositCoinpayments,
    DepositModal,
    TransferModal,
    WithdrawalModal,
    TradingModal,
    BuyModal,
    TradingClose,
  },
  props: {
    visibleModal: Boolean,
    investCol: Number,
  },
  emits: ['sendDeposit', 'closeModal'],
  setup(props, { emit }) {
    const i18n = useI18n();
    const { state, dispatch } = useStore();
    const route = computed(() => useRoute());
    const accId = computed(() => route.value.params.accId);
    const accounts = computed(() => state.accounts.data);
    const tradingAccount = computed(
      () => state.tradingAccounts.data.find((acc) => acc.id == accId.value) ?? state.tradingAccounts.accountData,
    );
    const depositAcc = computed(() => state.accounts.depositAcc);
    const mainAcc = computed(() => state.accounts.mainAccount);
    const actionType = computed(() => route.value.params.actionType ?? 'deposit');
    const isLoading = computed(() => state.accounts.fileLoading);
    const windowSize = computed(() => window.innerWidth);

    // const error = computed(() => {
    //   if (state.accounts.error) {
    //     if (typeof state.accounts.error === 'object') {
    //       return Object.entries(state.accounts.error).map((value) => value[1][0]);
    //     } else {
    //       return state.accounts.error == 500 ? i18n.t('error.500') : state.accounts.error;
    //     }
    //   }
    //   return false;
    // });

    const error = computed(() => {
      const err = state.accounts.error ?? state.tradingAccounts.error;
      if (err) {
        if (typeof err === 'object') {
          return Object.entries(err).map((value) => value[1][0]);
        } else {
          return err == 500 ? i18n.t('error.500') : err;
        }
      }
      return false;
    });

    const modalTitle = computed(() => {
      let title;
      if (!actionType.value.includes('trading')) {
        title = i18n.t('start.' + actionType.value, 2);
      } else {
        title = i18n.t('start.' + actionType.value, { msg: tradingAccount.value.attributes.mt5_account_data.name });
      }
      return title;
    });

    const visiblePayHint = ref(false);
    const isDataSend = ref(false);
    const visibleSendModal = ref(false);

    const changePayInfo = () => {
      visiblePayHint.value = !visiblePayHint.value;
    };

    const dataNotSended = () => {
      isDataSend.value = false;
      //closeModal(true);
      //visibleSendModal.value = true;
    };

    const dataSended = () => {
      isDataSend.value = true;

      dispatch('getDashboard');
      dispatch('axiosAccountsGetData');
      closeModal(true);
      if (actionType.value == 'deposit') {
        visibleSendModal.value = true;
      }
    };

    const closeModal = (closeWithoutPush) => {
      emit('closeModal', typeof closeWithoutPush === 'boolean' ? true : false);
      dispatch('clearAccountErrors');
      visiblePayHint.value = false;
    };

    const closeSendModal = () => {
      visibleSendModal.value = false;
      closeModal();
    };
    onMounted(() => {
      if (!accounts.value) {
        dispatch('axiosAccountsGetData');
      }
    });

    watchEffect(() => {
      if (route.value.params.actionType) {
        dispatch('clearAccountErrors');
      }
      if (route.value.params.actionType == 'deposit') {
        dispatch('getCryptoAmount', {
          exchange_currency: 'BTC',
        });
      } else if (route.value.params.actionType == 'buy') {
        dispatch('getCryptoAmount', {
          exchange_currency: 'MTH',
        });
      }
    });

    return {
      actionType,
      tradingAccount,
      accounts,
      i18n,
      modalTitle,
      accId,
      visiblePayHint,
      isDataSend,
      visibleSendModal,
      mainAcc,
      depositCurrencies,
      depositAcc,
      isLoading,
      windowSize,
      error,
      closeModal,
      changePayInfo,
      dataNotSended,
      dataSended,
      closeSendModal,
    };
  },
};

export default ModalCash;
</script>
